/* ~~~~~~~~~~~~~~~~~ All Pages ~~~~~~~~~~~~~~~~~~ */

.page-container {
  background-color: #f3f3f3;
  width: 100vw;
  height: 92vh;
  margin-top: 8vh;
  overflow: scroll;
}

.corners {
  background: #f6f6f6;
  position: relative;
  box-shadow: 0 1px 7px hsla(0, 0%, 0%, 0.2);
}

.corners:after,
.corners:before {
  background: #e6e6e6;
  content: '';
  height: 50px;
  position: absolute;
  top: -25px;
  width: 100px;
  box-shadow: 0 5px 10px -7px hsla(0, 0%, 0%, 0.5);
}
.corners:after {
  left: -50px;
  transform: rotate(-45deg);
}
.corners:before {
  right: -50px;
  transform: rotate(45deg);
}

.inner-shadow {
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
}

.shadow {
  -webkit-box-shadow: 0 2px 8px 1px #666; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 2px 8px 1px #666; /* Firefox 3.5 - 3.6 */
  box-shadow: 0 2px 8px 1px #666;
}

.content-wrap {
  margin: 10vh 10vw 10vh 10vw;
  padding: 5vh 3vw 5vh 3vw;
  display: flex;
  flex-direction: column;
}

.text-wrap {
  font-size: 2vh;
  background-color: #ededed;
  justify-content: center;
  padding: 0 10vmin 3vmin 10vmin;
  border-radius: 2vmin;
  margin-bottom: 3vh;
}

a {
  text-decoration: none;
  color: inherit;
}

/* ~~~~~~~~~~~~~~~~~ App Info ~~~~~~~~~~~~~~~~~~ */
#app-info .content-wrap {
  flex-flow: row wrap;
  align-items: flex-start;
}

#app-info .text-wrap {
  flex: 2;
}

#app-info .svg-wrap {
  flex: 1;
  margin-right: 2vw;
}

#app-info img {
  width: 25vw;
}

/* ~~~~~~~~~~~~~~~~~ About Us ~~~~~~~~~~~~~~~~~~ */
.socials-wrapper {
  padding: 0 0 2vh 0;
  border-radius: 2vmin;
  display: flex;
  flex: 2;
  flex-direction: column;
  background-color: #ededed;
  justify-content: center;
  align-items: center;
}

.social-icons {
  display: flex;
  flex-direction: row;
}

.social-btn {
  margin-left: 1vw !important;
  margin-right: 1vw !important;
}

.content-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: stretch;
}

#about-us .svg-wrap {
  flex: 1;
  margin-left: 2vw;
}

#about-us img {
  width: 25vw;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Merch ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.merch-btn {
  font-size: 4vh;
}

#merch .content-wrap {
  flex-flow: row wrap;
  align-items: flex-start;
}

#merch .text-wrap {
  flex: 2;
}

#merch .svg-wrap {
  flex: 1;
  margin-left: 2vw;
}

#merch img {
  width: 25vw;
}

/* ~~~~~~~~~~~~~~~~~ Find Out More ~~~~~~~~~~~~~~~~~~ */

.btnSubmit {
  padding: 1vh;
  margin: 1vh;
  font-size: 2vh;
}

.emailInput {
  padding: 1vh;
  font-size: 2vh;
}

.donationP {
  font-weight: bold;
  font-size: 3vh;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 404 ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.fof-wrap {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10vh;
}

#fof img {
  margin-bottom: 10vh;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~ Mobile Overrides ~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (max-width: 600px) {
  #app-info .content-wrap {
    flex-flow: column wrap;
    align-items: center;
  }

  #app-info .svg-wrap {
    padding: 0 0 2vh 0;
    margin: 0;
  }

  #app-info img {
    width: 80%;
  }

  #merch .content-wrap {
    flex-flow: column wrap;
    align-items: center;
  }

  #merch .svg-wrap {
    padding: 2vh 0 0 0;
    margin: 0;
  }
  
  #merch img {
    width: 80%;
  }

  .social-icons {
    flex-direction: column;
  }

  .social-icons .aws-btn {
    margin-bottom: 2vh;
  }

  .socials-wrapper {
    width: 100%;
  }

  .content-row {
    flex-flow: column wrap;
    align-items: center;
  }

  .svg-wrap {
    width: 100%;
    padding-top: 2vh;
    margin: 0;
  }

  #about-us img {
    width: 80%;
  }
}