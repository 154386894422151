.App-logo {
  height: 6vmin;
  /* pointer-events: none; */
  margin-left: 2vw;
}

.vl {
  border-left: 0.1vw solid #333;
  height: 6vmin;
  margin-left: 1vw;
}

.App-title {
  height: 6vmin;
  pointer-events: none;
  margin: 0 1vw;
  font-size: 5vmin;
  font-weight: 400;
}

#Nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
}

#Nav-list li {
  cursor: default;
  padding: 1vw;
  font-size: 3vmin;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#Nav-list li:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.nav-link {
  text-decoration: none;
  color: #000;
}

.navbar {
  background-color: #fff;
  min-height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  -webkit-box-shadow: 0 2px 8px 1px #666; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 2px 8px 1px #666; /* Firefox 3.5 - 3.6 */
  box-shadow: 0 2px 8px 1px #666;
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.logo {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .navbar {
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: transform 0.3s cubic-bezier(0, 0.53, 0, 1);
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .logo {
    padding-bottom: 3vh;
  }

  .nav-link {
    font-size: 5vh;
  }

  .navbar.hide {
    transform: translate3d(0, -90vh, 0);
  }

  .navbar.show {
    height: -webkit-fill-available;
    transform: translate3d(0, 0vh, 0);
    overflow: hidden;
  }

  #Nav-list {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;
    margin-top: 10vh;
  }

  #Nav-list li {
    padding-top: 5vh;
  }
}
