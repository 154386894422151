.app-viewport {
  position: absolute;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.app-background {
  position: relative;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 140%;
  width: 140%;
  margin-top: -20%;
  margin-left: -20%;
  overflow: hidden;
  transform: rotate(6deg);
  z-index: -1;
  opacity: 0.8;
}

.event-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 4vh;
}

.app-background-animate .event-column:nth-child(even) {
  -webkit-animation: Event-column-even-scroll 30s infinite alternate;
  animation: Event-column-even-scroll 30s infinite alternate;
}

.app-background-animate .event-column:nth-child(odd) {
  -webkit-animation: Event-column-odd-scroll 30s infinite alternate;
  animation: Event-column-odd-scroll 30s infinite alternate;
}

.event-container {
  display: flex;
  flex-direction: column;
  border-radius: 1vh;
  background-color: #ddd;
  flex: 1;
  overflow: hidden;
  margin-bottom: 4vh;
  height: 16vh;
  width: 16vh;
  -webkit-box-shadow: 2px 2px 7px 2px #888; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 2px 2px 7px 2px #888; /* Firefox 3.5 - 3.6 */
  box-shadow: 2px 2px 7px 2px #888;
}

.event-image {
  height: 100%;
  width: 100%;
}

.announcement-width {
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  top: 30%;
}

.announcement-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.3);
  padding-left: 2vw;
  padding-right: 2vw;
  margin-left: 15vw;
  margin-right: 15vw;
  -webkit-box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.3); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.3); /* Firefox 3.5 - 3.6 */
  box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.3);
}

.announcement-text {
  font-weight: 400;
  font-size: 5vh;
  opacity: 1;
  color: #fff;
  margin-bottom: 0;
}

.delay-explanation-text {
  font-weight: 400;
  font-size: 2vh;
  opacity: 1;
  color: #fff;
}

.slogan-text {
  font-weight: 500;
  font-size: 7vh;
  opacity: 1;
  color: #fff;
}

@keyframes Event-column-even-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30vh);
  }
}

@keyframes Event-column-odd-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(30vh);
  }
}
